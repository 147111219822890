import React from 'react'
import PropTypes from 'prop-types'

const Perfil = props => {
  return (
    <div>Perfil</div>
  )
}

Perfil.propTypes = {}

export default Perfil