import React from 'react'
import PropTypes from 'prop-types'

const Contacto = props => {
  return (
    <div>Contacto</div>
  )
}

Contacto.propTypes = {}

export default Contacto